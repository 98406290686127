<template>
    <default-template
        title="温熱・省エネ設備機器ポータルサイト(住宅版)"
        :is-visible-public="false"
        :is-visible-logout="false"
        :is-visible-pass-update="false"
        :is-visible-jigyosya-login="true"
        :is-house="true"
        :is-visible-breadcrumb="true"
        :breadcrumb-category="'太陽熱温水器一覧'"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">太陽熱温水器一覧</h2>
            </div>
            <div class="bl_eleHeatPump_links">
                <div class="bl_eleHeatPump_links_innerWrapper">
                    <h5 class="el_lv5Heading"><span class="bl_eleHeatPump_links_innerWrapper_symbol"></span>効率を確認する</h5>
                    <app-button
                        name="事業者リンク"
                        @click="showLinks"
                        ref="jest-public-solar-water-heater-show-links"
                        class="inline"
                    />
                </div>
                <div class="bl_eleHeatPump_links_innerWrapper">
                    <h5 class="el_lv5Heading"><span class="bl_eleHeatPump_links_innerWrapper_symbol"></span>機器ごとの詳細パラメータを確認する</h5>
                    <app-button
                        name="機器リストダウンロード"
                        @click="download"
                        ref="jest-public-solar-water-heater-download"
                        class="inline"
                    />
                </div>
            </div>
            <div>
                <div class="bl_searchBox_outer">
                    <p class="bl_searchBox_outer_msg">検索条件を入力してください。</p>
                    <p class="bl_searchBox_outer_updateDate">最終更新日：{{ lastUpdateDate }}</p>
                    <hr class="hp_flexspacer"/>
                    <div class="bl_searchBox ly_searchBox">
                        <div class="uq_flexcolumn">
                            <div class="bl_input_container">
                                <app-label
                                    name="brand_name"
                                    label="ブランド事業者名"
                                />
                                <app-select
                                    v-model="brand_name"
                                    :label="'選択してください'"
                                    :vmodel-label="brand_name"
                                    ref="jest-public-brand-name"
                                >
                                    <app-select-option
                                        :value="''"
                                        v-model="brand_name"
                                    >
                                        選択してください
                                    </app-select-option>
                                    <app-select-option
                                        v-for="(item, index) in brandNames"
                                        :key="index"
                                        :value="item"
                                        v-model="brand_name"
                                    >
                                        {{item}}
                                    </app-select-option>
                                </app-select>
                            </div>
                            <div class="bl_input_container bl_input_container_column">
                                <app-text
                                    type="date"
                                    name="release_date_from"
                                    ref="jest-release-date-from"
                                    v-model="release_date_from"
                                    label="発売予定年月日"
                                    class="formrow"
                                    unit="以降"
                                />
                                <div class="uq_include_expired">
                                    <input
                                        type="checkbox"
                                        v-model="include_expired"
                                        id="include_expired"
                                        name="include_expired"
                                        ref="jest-include-expired"
                                        class="el_checkbox_input"
                                    />
                                    <label for="include_expired"
                                        :class="{'checked':include_expired}"
                                        class="el_checkbox_label">販売終了等のパッケージを表示</label>
                                </div>
                            </div>
                        </div>
                        <div class="bl_input_container bl_input_container_column">
                            <app-text
                                name="system_type"
                                v-model="system_type"
                                label="システム型式"
                                class="formrow  wd_23em"
                            />
                            <app-text
                                name="certificate_number"
                                v-model="certificate_number"
                                label="成績証明書番号等"
                                class="formrow  wd_23em"
                            />
                        </div>
                        <div class="uq_flex_end">
                            <app-button
                                name="検索"
                                @click="search"
                                ref="jest-public-solar-water-heater-search"
                                class="inline wd_100px"
                            />
                        </div>
                    </div>
                    <div class="bl_searchBox_outer_info">
                        <h5>機器リストダウンロードについて</h5>
                        <p>機器リストについては、ポータルサイトに登録されている最新の情報を表示したものであり、エネルギー消費性能計算プログラム（住宅版）に反映されていない機器が含まれる場合がございます。予めご了承ください。</p>
                    </div>
                </div>
            </div>
            <p v-if="total != 0">{{total}}件中{{start}}～{{end}}件表示</p>
            <table class="lists_table">
                <thead>
                    <tr>
                        <th class="lists_table_brand_name"><span>ブランド</span><span>事業者名</span></th>
                        <th class="lists_table_system_type"><span>システム</span><span>型式</span></th>
                        <th class="lists_table_certificate_number"><span>成績証明書</span><span>番号</span></th>
                        <th class="lists_table_releaseYearMonth"><span>発売予定</span><span>年月</span></th>
                        <th><span>販売</span><span>終了等</span></th>
                        <th class="hp_alignLeft"></th>
                    </tr>
                </thead>
                <tr
                    v-for="item in solarWaterHeaters"
                    :key="'solarWaterHeaters' + item.id"
                    :ref="'solarWaterHeaters' + item.id"
                >
                    <td>{{item.brand_name.name}}</td> 
                    <td>{{item.system_type}}</td> 
                    <td
                        @click="getFile(item)"
                        :class="{clickable:isNotCanceled(item.status)}"
                        :ref="'solarWaterHeaterCertificate' + item.id"
                    >
                        {{item.certificate_number}}
                    </td> 
                    <td>{{releaseYearMonth(item.release_date)}}</td> 
                    <td><span v-if="item.status == 5">●</span></td> 
                    <td class="relative">
                        <app-button
                            name="コピー"
                            @click="copy(item)"
                            @mouseover="over(item.id)"
                            @mouseleave="leave()"
                            :ref="'jest-public-solar-water-heater-copy' + item.id"
                            class="inline outlined wd_80px"
                        />
                        <div
                            class="hover"
                            v-if="hover(item.id)"
                        >
                            <p v-if="!copied" class="hover_explain">品番等コピーとはエネルギー消費性能計算プログラムの検索ボックスに貼り付ける品番などの情報をコピーする機能です</p>
                            <div
                                class="popup"
                                v-if="copied"
                            >
                                <p class="hover_copied">コピーしました。</p>
                            </div>
                        </div>
                        <app-button
                            name="詳細"
                            @click="toDetail(item.id)"
                            :ref="'jest-public-solar-water-heater-to-detail' + item.id"
                            class="inline wd_80px"
                        />
                    </td> 
                </tr>

            </table>
            <list-pager
                :page="page"
                :page-count="pageCount"
                @page-change="pageChange"
            />
            <app-button
                name="戻る"
                class="cancel"
                @click="back"
                ref="jest-public-links-back"
            />
            <p
                id="copy"
                v-show="false"
            />
            <div
                id="invisible"
                v-show="false"
            />
        </div>
    </default-template>
</template>

<script>
/**
 * P016 太陽熱温水器一覧
 * 
 * [算出プロパティ]
 * lastUpdateDate...日本語表記の最終更新年月日
 * releaseYearMonth...日本語表記の発売年月
 * hover...指定されたidの位置のコピーボタンがホバーされているか否か
 * 
 * [イベント処理]
 * copy...クリック箇所に応じたブランド名、ヒートポンプユニット番号、貯湯ユニット形式、補助熱源機品番、成績証明書番号のテキストをクリップボードにコピーする
 * closePopup...コピー時に表示されるポップアップを閉じる
 * toDetail...詳細画面への遷移
 * over...mouseoverしたボタンのidをdataに設定
 * leave...mouseoverしたボタンのid情報を削除
 * toLogin...ログイン画面へ遷移
 * back...カテゴリー一覧(住宅版)へ遷移
 */
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import { mapComputedProperties, move, isSetNumber, pageContentCount } from '@/util'
import ListPager from '@/components/molecules/ListPager'
import pdf from "@/mixins/pdf"

export default {
    name:'PublicSolarWaterHeaters',
    components:{
        ListPager
    },
    data(){
        return {
            copied:false,
            hovered:null
        }
    },
    mixins:[pdf],
    computed:{
        ...mapGetters({
            solarWaterHeaters:'solarWaterHeaters/solarWaterHeaters',
            lastUpdate:'solarWaterHeaters/lastUpdate',
            total:'solarWaterHeaters/total',
            brandNames:'solarWaterHeaters/brandNames',
            data:'solarWaterHeaters/searchSolarWaterHeatersData'
        }),
        ...mapComputedProperties([
            'brand_name',
            'release_date_from',
            'include_expired',
            'system_type',
            'certificate_number',
            'page'
        ]),
        lastUpdateDate(){
            return moment(this.lastUpdate).format('YYYY年MM月DD日')
        },
        releaseYearMonth(){
            return function(date){
                return moment(date).format('YYYY年MM月')
            }
        },
        hover(){
            return function(id) {
                return this.hovered === id
            }
        },
        pageCount(){
            return Math.ceil(this.total / pageContentCount)
        },
        start(){
            return 1 + (this.page - 1) * pageContentCount
        },
        end(){
            const count = this.page * pageContentCount
            return count < this.total ? count : this.total
        }
    },
    async mounted(){
        const loader = this.$loading.show()
        await Promise.all([
            this.searchSolarWaterHeaters({page:this.page, publicFlag:true}),
            this.getBrandNames()
        ])
        loader.hide()
    },
    methods:{
        ...mapActions({
            searchSolarWaterHeaters:'solarWaterHeaters/search',
            getBrandNames:'solarWaterHeaters/getBrandNames',
            csvDownload:'solarWaterHeaters/download',
            update:'solarWaterHeaters/updateSearchSolarWaterHeatersData',
            getDeviceCertificateFile:'solarWaterHeaters/getDeviceCertificateFile',
            linksUpdate:'links/update',
        }),
        async search(){
            const loader = this.$loading.show()
            await this.searchSolarWaterHeaters({page:1, publicFlag:true})
            loader.hide()
        },
        copy(item) {
            // コピーするテキストの作成
            const text = item.brand_name.name.concat(isSetNumber(item.system_type), isSetNumber(item.certificate_number))
            // コピーを実行
            this.$copyText(text)
                .then(() => {
                    this.copied = true
                })
        },
        closePopup(){
            this.copied = false
        },
        async download(){
            const loader = this.$loading.show()
            await this.csvDownload()
            loader.hide()
        },
        toDetail(id){
            move(this.$router, this.$route.path + '/' + id)
        },
        over(id){
            this.hovered = id
        },
        leave(){
            this.hovered = null
            this.closePopup()
        },
        back(){
            move(this.$router, '/house/links')
        },
        async pageChange(page){
            const loader = this.$loading.show()
            await this.searchSolarWaterHeaters({page, publicFlag:true})
            loader.hide()
        },
        showLinks(){
            this.linksUpdate({
                selectedCategoryCode: '1037',
                selectedCategoryName: '太陽熱温水器',
                showingLevel1: '一次エネルギー消費量',
                showingLevel2: '給湯設備',
                showingLevel3: '太陽熱給湯'
            })
                .then(() => {
                    move(this.$router, '/house/links/organizations')
                })
        },
        /**
         * 当該機器データに紐づく成績証明書等PDFを別ウィンドウで表示する
         */
        async getFile(item){
            if (this.isNotCanceled(item.status)) {
                const file = await this.getDeviceCertificateFile(item.solar_water_heater_certificate_id)
                this.openPdf(file, 'Certification')
            }
        },
        isNotCanceled(status){
            return status !== 5
        },
    }
}
</script>

<style scoped>
.bl_eleHeatPump_links{
    display: flex;
    flex-direction: column;
}
.bl_eleHeatPump_links_innerWrapper{
    display: flex;
    width: 100%;
    margin-top: 8px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.bl_eleHeatPump_links_innerWrapper:not(:first-child){
    margin-top: 8px;
    margin-bottom: 8px;
    padding-top: 8px;
    border-top: 1px solid #4A6533;
}
.bl_eleHeatPump_links_innerWrapper .el_lv5Heading{
    display: flex;
    width: 100%;
    margin-top: 0;
    flex-direction: row;
    align-items: center;
}
.bl_eleHeatPump_links_innerWrapper_symbol{
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 12px;
    background-color: #2c3e50;
}
.bl_eleHeatPump_links_innerWrapper .el_button{
    width: 225px;
    margin-right: 0;
    white-space: nowrap;
    padding-right: 22px;
    padding-left: 22px;
    height: 36px;
    border-radius: 18px;
    background-color: #3D9A46;
}
.bl_searchBox{
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 770px;
    max-width: 800px;
}
.bl_searchBox_outer_info{
    max-width: 265px;
    margin-left:  auto;
    padding: 16px 24px;
    font-size: 12px;
    background-color: #f1f4ec;
}
.bl_searchBox_outer_info>h5{
    font-size: 14px;
    margin: 0 0 4px;
}
.bl_searchBox_outer_info>p{
    margin: 0;
}
.uq_flexcolumn{
    display: flex;
    flex-direction: column;
    margin-right: 16px;
}
.uq_flexcolumn >>> .select_btn{
    min-width: 148px;
}
.bl_searchBox >>> .select_wrapper div.select_btn{
    width: 19em;
}
.uq_flexcolumn >>> .select_wrapper label{
    width: 100%;
}
.uq_flexcolumn >>> label{
    width: 7.5em;
    font-weight: 600;
    font-size: 12px;
}
.uq_flexcolumn >>> p{
    font-weight: 600;
    font-size: 12px;
}
.uq_flex_end{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    width: fit-content;
    height: fit-content;
    margin: 4px 5px 0 auto;
}
.uq_flex_end >>> button.el_button {
    margin: 0 0 0 auto;
}
.bl_searchBox>.bl_input_container:not(:last-of-type){
    margin-right: 0;
    margin-bottom: 0;
}
.bl_searchBox .bl_input_container_column{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.uq_include_expired{
    display: flex;
    flex-direction: row;
    margin-top: 16px;
}
.wd_23em{
    width: 23em;
}
.bl_searchBox .input_container.wd_23em >>> label{
    width: 11em;
}
.lists_table{
    width: 100%;
}
.hover{
    position: absolute;
    top: -0.5rem;
    right: 168px;
    width: fit-content;
    padding: 16px;
    background-color: #FFF;
    border: 1px solid #000;
    box-shadow: 0 1px 1px 2px rgba(0,0,0, .1);
    font-weight: bolder;
    z-index: 980;
}
.hover p{
    margin: 0;
}
.hover_explain{
    min-width: 20em;
}
.hover_copied{
    white-space: nowrap;
}
.relative{
    width: 168px;
    max-width: 176px;
    position: relative;
    padding: 0 ;
}
.relative>>>button{
    margin: 4px 2px;
}
.clickable:hover{
    text-decoration: underline;
    cursor: pointer;
}
/* タブレット縦よりウインドウ幅が狭いもの */
.uq_btnCopy__front{
    z-index:995;
}
 /* タブレット縦よりウインドウ幅が狭いもの */
@media screen and (max-width: 1000px) {
    .relative{
        width: 80px;
        min-width: 80px;
    }
}

 /* タブレット縦よりウインドウ幅が狭いもの */
@media screen and (max-width: 1380px) {
    .bl_searchBox_outer_info{
        max-width: 100%;
        margin-top: 8px;
    }
}
</style>